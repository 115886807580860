import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES } from "../components/seo";
import PlansConatiner from "../components/plansConatiner";
import { translations } from "../locales/pricing.js";
import kolibirdBox from "../assets/img/buyInvitation/kolibirdBox.svg";

const PricingPage = () => {
  return (
    <Layout page="pricing" language="es">
      <main className="pricing-page-container container">
        <div className="price-img-container">
          <img src={kolibirdBox} alt="buy invitation image" />
        </div>
        <div>
          <h1 className="price-page-title">{translations.es.pageTitle}</h1>
          <p>{translations.es.explanation1}</p>
          <p>{translations.es.explanation2}</p>
          <PlansConatiner language="es" page="true" />
        </div>
      </main>
    </Layout>
  );
};

export default PricingPage;

export const Head = () => <SeoES hrefLangs={[{href: "https://kolibird.app/pricing/", lang: "es"}, {href: "https://kolibird.app/en/pricing/", lang: "en"}]}/>;
